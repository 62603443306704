@import "../../style-variables.scss";

#vicbot {
    .error-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        background-color: $error-background;
        text-align: center;

        span {
            color: $vic-blue;
            cursor: pointer;
        }

        .error-image {
            color: $text-color-a;
            background-color: $error-image-background;
            height: 70px;
            width: 70px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            p {
              font-size: 50px;  
            }
        }


    }
}