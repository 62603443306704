
#vicbot {
  .menu {
    padding: 5px;
    position: fixed;
    right:45px;
    bottom: 70%;
    width: 200px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 2px 4px grey;
    animation: menu-appear .7s ease;
  }

  @media only screen and (max-width: 600px) {
    .menu {
      bottom: 85%;
      right: 25px;
    }
  }

  @keyframes menu-appear {
    0%   { opacity: .1;}
    100% { opacity: 1; }
  }
}