
#vicbot {

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        .main-title {
            .title-text {
                p{
                    letter-spacing: .5px;
                }
            }
        }
        .wrapper {
            .message-container-user { 
             
                border-radius: 20px;
            
            }
        }
        
      }


      @supports (-ms-accelerator:true) {
        /* IE Edge 12+ CSS styles go here */ 

        .message-container-user { 
            .wrapper {
             border-radius: 15px;
            }
        }
    }

}