@import "../../../style-variables.scss";


#vicbot {

    .quick-reply {

        button, .react-link {
            font-size: 14px;
            padding: 10px;
            margin: 5px;
            background: $chatbot-quickreply-container-color !important;
            color: $chatbot-quickreply-font-color;
            border: 2px solid $chatbot-quickreply-border-color;
            box-sizing: border-box;
            border-radius: 37px;
            text-decoration: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .MuiSvgIcon-root {
                margin-right: 5px;
            }
        }
    }

}