// primary colours
$vic-blue: #0052c2;
$vic-blue-hover: rgb(102, 156, 233);
$vic-grey: #F6F6F9;
$text-color-a: white;
$text-color-b: #132A4A;
$error-red: #AF272F;

// toggle
$chatbot-toggle-color: $vic-blue;
$chatbot-toggle-hover-color: $vic-blue-hover;

// header

$header-color: $vic-blue;
$header-text-color: $text-color-a;
$header-subtitle-color: $vic-grey;
$header-buttons-color: $text-color-a;

// chatbot avatar
$avatar-background-color: $vic-blue;
$avatar-color: $text-color-a;

// messages from bot
$chatbot-message-container-color: $vic-grey;
$chatbot-quickreply-container-color:  $text-color-a;
$chatbot-quickreply-border-color: $vic-blue;
$chatbot-quickreply-font-color: $vic-blue;
$chatbot-card-button-color:  none;
$chatbot-message-text-color: $text-color-b;

// messages from user
$user-message-container-color: $vic-blue;
$user-message-text-color: $text-color-a;

// typing dots
$typing-dot-color: $vic-blue;
$typing-dot-background-color:none;

// user input
$text-input-color: rgb(238, 238, 238);

// submit button
$submit-button-color: $vic-blue;
$submit-icon-color: $text-color-a;

// error message
$error-image-background: $error-red;
$error-background: $vic-grey;