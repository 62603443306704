@import "../../../style-variables.scss";


#vicbot {

    .info-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 20px 0px;
        max-height: 1000px;
        
        .info-container {
            width: 95%;
            display: flex;
            flex-direction: column;
            max-height: 200px;
            border: 2px solid $chatbot-quickreply-border-color !important;
            border-radius: 15px;
        
            .main-section {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 100%;
        
                .info {
                    flex: .2;
                    margin-right: 2px;
                    margin-left: 2px;
                    .icon {
                        font-size: 20px;
                        padding: 0;
                        margin: 0;
                        text-align: center;
                        color: $chatbot-quickreply-border-color !important;
                        font-weight: bold;
                    }
                }
        
                .message-box {
                    flex: .8;
                    margin: 5px;
                    p {
                        padding: 10px;
                        margin: 0px;
                    }
                }
            }

            .restart-button {
                font-size: 14px;
                padding: 10px;
                margin: 5px;
                background: $chatbot-quickreply-border-color !important;
                color: $chatbot-quickreply-container-color;
                border: 2px solid $chatbot-quickreply-font-color;
                box-sizing: border-box;
                border-radius: 37px;
                text-decoration: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        
        }
    }
}
