@import "../../../style-variables.scss";

#vicbot {

	div#wave {
	    display: flex;
	    justify-content: center;
	    align-items: center;
			text-align:center;
			width:45px;
			height:25px;
	    margin: 15px auto 10px 10px;
	    border-radius: 50px;
	    background: $typing-dot-background-color !important;
		.dot {
			width:6px;
			height:6px;
			border-radius:50%;
			margin-right:3px;
			background:$typing-dot-color !important;
			animation: wave 1.3s linear infinite;

			&:nth-child(2) {
				animation-delay: -1.1s;
			}

			&:nth-child(3) {
				animation-delay: -0.9s;
			}
		}
	}

	@keyframes wave {
		0%, 60%, 100% {
			transform: initial;
		}

		30% {
			transform: translateY(-8px);
		}
	}
}