#vicbot {
  margin: 0;
  padding: 0;
}

#vicbot > * {
  margin: 0;
  padding: 0;
  font-family: 'VIC-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#vicbot p {
    font-family: 'VIC-Regular', sans-serif;
    font-size: 14px;
}

#vicbot a {
  text-decoration: none;
}