@import "../../../style-variables.scss";

#vicbot {

    .card {
        width: 200px;
        height: 280px;
        overflow-x: hidden;
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin: 10px 20px;
        text-align: center;
        background-color: rgb(255, 255, 255);
        box-shadow: 2px 2px 5px grey;
        border-radius: 5px;

        h3 {
            font-size: 14px;
            color: black;
        }

        p {
            font-size: 14px;
            color: black;
            text-align: left;
        }

        .price {
            color: #D64C4C;
        }

        .img-container {
            width: 100%;
            height: 40%;
            overflow: hidden;
            vertical-align: middle;

            img {
                width: 150%;
                height: auto;
                object-fit: cover;
                vertical-align: middle;
            }  
        }

        .card-arrow {
          width: 16px;
          height: auto;
          margin: 10px;
        }

        button {
            padding: 7px;
            border-radius: 5px;
            font-size: 18px;
            margin: 10px;
            background: $chatbot-card-button-color !important;
            color: antiquewhite;
            border: none;
            cursor: pointer;
        }
    }
}