@import "../../style-variables.scss";



#vicbot > * {
  &:focus {
    outline-style: none;
  }
}


#vicbot {

  .chatbot-hidden-button {
    z-index: 10000;
    background-color: $chatbot-toggle-color !important;
    position: fixed;
    border-radius: 30px;
    right: 24px;
    bottom: 300px;
    height: 44px;
    width: 150px;
    box-shadow: 2px 2px 7px grey;
    cursor: pointer;
    overflow: hidden;
    transform: rotate(-90deg);
    transform-origin: right 60% !important;

    &:hover {
      background-color: $chatbot-toggle-hover-color !important;
    }

    .message-pic {
      margin-right: 5px;
    }

    .cbtext {
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 100%;

      p {
        margin-left: 5px;
        transition: 0.5s;
      }
    }
  }

  .chatbot-button {
    z-index: 10000;
    background-color: $chatbot-toggle-color !important;
    position: fixed;
    border-radius: 100%;
    right: 30px;
    bottom: 30px;
    height: 60px;
    width: 60px;
    box-shadow: 2px 2px 7px grey;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      background-color: $chatbot-toggle-hover-color !important;
    }

    .cbtext {
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 100%;

      p {
        transition: 0.5s;
      }
    }
  }

  .chatbot {
    display: flex;
    flex-direction: column;
    z-index: 10000;
    position: fixed;
    border-radius: 10px;
    right: 30px;
    bottom: 10px;
    height: 80%;
    width: 380px;
    box-shadow: 2px 2px 7px grey;
    background-color: white;
    -webkit-animation: growbot 0.7s ease;
    animation: growbot 0.7s ease;

    &.disappear {
      opacity: 0;
      height: 0%;
      width: 0%;
      position: fixed;
      right: 24px;
      bottom: 300px;
      transition: 0.7s;
    }

    .logo {
      width: 20%;
      height: auto;
    }

    .messages-container {
      position: relative;
      flex: 1;
      background-color: white;
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 67px;
      -webkit-animation: fade-message 0.6s ease;
      animation: fade-message 0.6s ease;

      &.disappear {
        opacity: 0;
        height: 0%;
        width: 0%;
      }
    }

    .main-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px 10px 0px 0px;
      background-color: $header-color;
      height: 60px;
      padding: 5px;
      color: $header-text-color !important;

      .logo-section {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 50%;

        .cos-logo {
          width: 25%;
          height: auto;
          padding-left: 5px;
        }

        .title-text {
          h4 {
            color: $header-text-color;
            font-size: 15px;
            margin: 0 0 0.2em;
          }

          p {
            color: $header-subtitle-color;
            font-size: 12px;
            margin: 0;
          }
        }
      }

      .print-icon {
        cursor: pointer;
        width: 32px;
        height: auto;
        margin-right: 10px;
        box-shadow: 2px 2px 7px grey;
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 0px 10px 0px 0px;
        max-width: 60%;

        .header-button {
          height: auto;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 5px;
          color: $header-buttons-color !important;
          margin: 0px 5px;
          &:hover {
            background-color: rgba(134, 209, 255, 0.1);
            transition: 0.3s;
          }
        }
      }

      .close-button,
      .injection-button {
        cursor: pointer;
        line-height: 0;
        display: flex;
        font-weight: bold;
        font-size: 20px;
        margin: 0px 10px 0 0;
        color: $header-buttons-color !important;

        p {
          color: #aca4a4 !important;
        }

        &:hover {
          color: #aca4a4 !important;
          transition: 0.5s;
        }
      }

      .injection-button {
        font-size: 14px;
      }
    }

    form {
      position: absolute;
      background-color: white;
      bottom: 0px;
      padding: 8px 0;
      height: 60px;
      width: 100%;
      border-radius: 0 0px 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .user-input {
      width: 75%;
      height: 25px;
      font-size: 14px;
      border: none;
      padding: 7px;
      background-color: $text-input-color;
      padding-left: 10px;
      border-radius: 20px;
      
    }

    .submit {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      background: $submit-button-color;
      border: none;
      border-radius: 100px;
      color: $submit-icon-color;
      cursor: pointer;
      opacity: 0.6;
    }

    .submit-active {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      background: $submit-button-color;
      border: none;
      border-radius: 100px;
      color: $submit-icon-color;
      cursor: pointer;
    }
    .input-buttons {
      border-radius: 0px 0px 10px 0px;
      width: 10%;
      height: 50px;
      background: $submit-button-color !important;
      border: none;
      color: $submit-icon-color !important;
      cursor: pointer;
      transition: 0.3s;

      #paperclip {
        width: 22px;
        height: 22px;
        fill: $submit-icon-color !important;
        transition: 0.3s;

        :hover {
          fill: #1b2d38;
          transition: 0.3s;
        }
      }

      :hover {
        color: #1b2d38;
        transition: 0.3s;
        fill: #1b2d38;
      }
    }
  }

  @keyframes growbot {
    0% {
      position: fixed;
      right: 24px;
      bottom: 300px;
      height: 10%;
      width: 10%;
      opacity: 0;
    }
    100% {
      height: 80%;
      width: 380px;
      opacity: 1;
    }
  }

  @keyframes fade-message {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @media only screen and (max-width: 850px) {
    .chatbot {
      height: 95%;
      width: 95%;
      right: 10px;
      bottom: 10px;

      .messages-container {
        width: 100%;
      }

      .text {
        display: flex;
        justify-content: space-around;
        margin: 10px 0px !important;
      }

      .close-button,
      .injection-button {
        cursor: pointer;
        line-height: 0;
        display: flex;
        font-size: 15px;
        margin: 0 10px !important;
      }

    }

    .chatbot-button{
      display:none;
    }

    .user-input {
      width: 70%;
    }

    @keyframes growbot {
      0% {
        position: fixed;
        right: 24px;
        bottom: 300px;
        height: 10%;
        width: 10%;
      }
      100% {
        height: 95%;
        width: 95%;
      }
    }

  }
}